import React, { ReactNode } from 'react'

import Box, { BoxProps } from '@mui/material/Box'

export default function ExternalPageWrapper({ children, ...props }: { children: ReactNode } & BoxProps) {
  return (
    <Box component="article" px={4.5} py={3} {...props}>
      {children}
    </Box>
  )
}
