import React, { ReactNode } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import ExternalPageBackButton from 'components/templates/ExternalPage/ExternalPageBackButton'

export default function ExternalPageHeader({ children, back }: { children: ReactNode; back?: ReactNode }) {
  return (
    <Box component="header" color="common.black" textAlign="center" position="relative">
      {back && <ExternalPageBackButton back={back} />}
      <Typography variant="headlineMedium">{children}</Typography>
    </Box>
  )
}
